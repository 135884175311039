<template lang="pug">
include ../pug/svg
section#learning
	div#ctrl
		div
			Search(v-model:search="search")
	div.wrapper
		div.c1.listbg
			ul.list
				li.course(v-for="(course, index) in filteredCourses" :key="course.id" :course="course" :index="index" @mouseenter="previewIndex=index" :class="{active:previewIndex===index}")
					picture
						img(v-if="course.media" :src="course.media.mediaUrl" :alt="course.media.seoAlt")
						img(v-else-if="course.mediaEmbedUrl" :src="course.mediaEmbedUrl")
					div.hgroup
						h2 {{course.name}}
						h3 {{courseStatus(course.publishStatus)}}
					a.arrow View {{terminology('course','course','singular')}}
						+svg(svg-filename='iconArrow')
					//-router-link.but.pri(:to="{name:'CourseSynopsis', params:{courseId:course.id}}" target="_blank") {{courseUserState(course)}} {{terminology('course','course','singular')}}
					//template(v-if="course.publishStatus==='live'")
					a.but.pri(v-if="preventResit(course)" class="disabled") {{courseUserState(course)}}
					a.but.pri(v-else-if="course.publishStatus!=='live'" class="disabled") {{courseUserState(course)}}
					router-link.but.pri(v-else :to="{name:'CourseProgress', params:{courseId:course.id,lessonId:defaultLessonId(course)}}") {{courseUserState(course)}}
					//- http://thinkworks.thinkhub-vuejs.uk:8089/18/progress/479
		div.c2.sticky(v-if="previewCourse")
			div.preview
				figure
					picture
						img(v-if="previewCourse.media" :src="previewCourse.media.mediaUrl" :alt="previewCourse.media.seoAlt")
						img(v-else-if="previewCourse.mediaEmbedUrl" :src="previewCourse.mediaEmbedUrl")
					figcaption
						span {{previewCourse.name}}
				div.body
					h5 {{terminology('course','course','singular')}} Status
					p {{courseStatus(previewCourse.publishStatus)}}
					//-picture.user(v-if="user.profileUrl" :class="{online:user.online}")
						img(:src="user.profileUrl" width="40" height="40" :alt="user.fullName")
					h5 {{terminology('course','course','singular')}} Summary
					table.numeric
						tr
							th {{terminology('course','lesson','plural')}}
							th Tasks
							th {{terminology('user','learner','plural')}}
							th {{terminology('user','mentor','plural')}}
						tr
							td {{courseLessonCount(previewCourse)}}
							td {{courseTaskCount(previewCourse)}}
							td {{courseLearnerCount(previewCourse)}}
							td {{courseMentorCount(previewCourse)}}
					//-h5 User Summary
					//-table.numeric
						tr
							th Admins
							th Mentors
							th Learners
						tr
							td {{courseAdminCount(previewCourse)}}
							td {{courseMentorCount(previewCourse)}}
							td {{courseLearnerCount(previewCourse)}}
					//-h5 Avg. Feedback Score
					//-ol.rating
						li(:class="{checked:avgFeedbackRating(previewCourse)>0}") 1 Star
							+svg(svg-filename="iconStar" aria-hidden="true" alt="Star icon")
						li(:class="{checked:avgFeedbackRating(previewCourse)>1}") 2 Stars
							+svg(svg-filename="iconStar" aria-hidden="true" alt="Star icon")
						li(:class="{checked:avgFeedbackRating(previewCourse)>2}") 3 Stars
							+svg(svg-filename="iconStar" aria-hidden="true" alt="Star icon")
						li(:class="{checked:avgFeedbackRating(previewCourse)>3}") 4 Stars
							+svg(svg-filename="iconStar" aria-hidden="true" alt="Star icon")
						li(:class="{checked:avgFeedbackRating(previewCourse)>4}") 5 Stars
							+svg(svg-filename="iconStar" aria-hidden="true" alt="Star icon")
					template(v-if="previewCourse.examMode")
						//-h5 Exam Summary
						//-ul
							li Can resit the exam
							li Can view lessons in any order.
							li Can return to previous lessons
						h5 Result Summary
						table.numeric
							tr
								th Attempts
								th Passes
								th Fails
							tr
								td {{courseAttempCount(previewCourse)}}
								td {{coursePassCount(previewCourse)}}
								td {{courseFailCount(previewCourse)}}	
</template>

<script>
import Search from '../components/Search';
//import Filter from '../components/Filter';

export default {
	name: 'Learn',
	props: ['state'],
	components: {
		Search,
//		Filter,
	},
	data() {
		return {
			search: null,
			previewIndex: 0,
		}
	},
	mounted() {
		this.refreshCourses();
	},
	computed: {
		user() {
			return this.$store.state.auth.user;
		},
		courses() {
			return this.$store.getters['courses/getUserCourses'];
		},
		filteredCourses() {
			//return this.courses;
			const nonArchivedUserCourses = this.courses.filter(c => {
				// restrict to active statuses
				if (c.publishStatus !== 'enrol' && c.publishStatus !== 'live') {
					return false;
				}
				
				// filter courses based on current view state
				const completedAttempt = c.courseUserActivities.find(a => a.userId === this.user.id && a.activityType === 'course' && a.activitySubtype === 'completed') || null;
				
				return (this.state === 'completed') ? (completedAttempt !== null) : (completedAttempt === null);
			});
			
			// serch term filter
			return (this.search) ? nonArchivedUserCourses.filter(c => c.name.toLowerCase().includes(this.search.toLowerCase())) : nonArchivedUserCourses;
		},
		previewCourse() {
			return this.filteredCourses[this.previewIndex];
		},
		statusFilters() {
			return this.$store.state.courses.courseStatuses;
		},
	},
	methods: {
		preventResit(course) {
			return (this.state === 'completed' && !course.courseAllowRetake);
		},
		defaultLessonId(course) {
			// return course entry point
			const lesson = course.courseLessons[0];
			let activities = [...course.courseUserActivities].filter(a => a.userId === this.user.id);
			let lessonId = null;
			
			if (lesson) {
				lessonId = lesson.id;
				
				if (activities.length) {
					// sort by id desc (most recent first)
					activities.sort((a,b) => b.id - a.id);
					
					const lastActivity = activities[0];
					
					if (lastActivity.activityType !== 'course' && lastActivity.activitySubtype !== 'completed') {
						// get latest stated lesson
						lessonId = activities.find(a => a.activityType === 'lesson' && a.activitySubtype === 'started').lessonId;
					}
				}
			}
			
			return lessonId;
		},
		courseStatus(publishStatus) {
			const status = this.statusFilters.find(s => s.value === publishStatus);
			return status.option;
		},
		courseUserState(course) {
			// copy store to local (allows list sorting)
			let activities = [...course.courseUserActivities].filter(a => a.userId === this.user.id);
			
			if (!activities.length) {
				return 'Start';
			}
			
			// sort by id desc (most recent first)
			activities.sort((a,b) => b.id - a.id);
			
			const lastActivity = activities[0];
			
			return (lastActivity.activityType === 'course' && lastActivity.activitySubtype === 'completed') ? 'Resit' : 'Resume';
		},		
		courseMentorCount(course) {
			return this.$store.getters['courses/getCourseMentorCount'](course.id);
		},
		courseLearnerCount(course) {
			return this.$store.getters['courses/getCourseLearnerCount'](course.id);
		},
		courseLessonCount(course) {
			return this.$store.getters['courses/getCourseLessonCount'](course.id);
		},
		courseTaskCount(course) {
			return this.$store.getters['courses/getCourseTaskCount'](course.id);
		},
		lessonBlockCount(course) {
			return this.$store.getters['courses/getLessonBlockCount'](course.id);
		},
		courseAttempCount(course) {
			return this.$store.getters['courses/getCompletedAttemptCount'](course.id, this.user.id);
		},
		coursePassCount(course) {
			return this.$store.getters['courses/getCompletedPassCount'](course.id, this.user.id);
		},
		courseFailCount(course) {
			return this.$store.getters['courses/getCompletedFailCount'](course.id, this.user.id);
		},
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
		async refreshCourses() {
			// load courses from db
			await this.$store.dispatch('courses/dbCourses');
		},
	},
	watch: {
		state: {
			immediate: true,
			handler() {
				// update header title
				const title = this.state[0].toUpperCase() + this.state.substring(1) + ' ' + this.terminology('course','course','plural');

				this.$store.dispatch('gui/setHeader', {
					title: title,
					backRoute: {
						text: 'Dashboard',
						name: 'Dashboard',
					},
				});
			},
		}
	},
}
</script>

<style lang="scss" scoped>
</style>
